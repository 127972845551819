import toast, { Toaster } from 'react-hot-toast';
import InfiniteScroll from 'react-infinite-scroll-component';

import React, { useCallback, useEffect, useRef, useState } from 'react';

//shared component
import { API_CONFIG } from 'shared/constants/constant';

//service configuration
import httpService, { axiosInstance } from 'shared/services/http.service';

//interfaces
import { Message } from './interface/chatbot';

//component
import { ChatIcon, CloseIcon, SendIcon } from 'shared/icon/icon';

let tokenData = { token: '', url: '', expiry: '' };

const isTokenExpires = () => {
    const currentTime = new Date().getTime();
    return new Date(tokenData.expiry).getTime() < currentTime
}


const UmWeltBot: React.FC = () => {
    const [messages, setMessages] = useState<Message[]>([{ role: 'assistant', text: 'Hallo, ich bin Gerd ein virtuelles Teammitglied bei GER Umweltschutz. Wie kann ich Ihnen helfen?' }]);
    const [typeLoading, setTypeLoading] = useState(false)
    const [messageText, setMessageText] = useState('');
    const [loading, setLoading] = useState(false);

    const lastMessageRef = useRef<HTMLDivElement | null>(null);
    const getToken = useCallback(async () => {
        try {
            const data = await httpService.post(`${API_CONFIG.path.token}`);
            tokenData = data;
        } catch (error) {
            toast.error('Failed to fetch token.');
            console.error('Token fetch error:', error);
        }
    }, []);

    // Function to send a message to the parent window to close the chatbot
    const closeChatbot = () => {
        window.parent.postMessage({ action: 'closeChatbot' }, '*');
    };


    useEffect(() => {
        getToken();
    }, [getToken]);

    const handleSubmit = useCallback(async (e: React.FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (!messageText.trim()) return;
        const newUserMessage: Message = {
            role: 'user',
            text: messageText
        };

        setMessages(prevMessages => [...prevMessages, newUserMessage]);
        const payload = {
            queryInput: {
                text: {
                    text: messageText,
                    languageCode: "de"
                }
            }
        }
        setMessageText('');
        if (isTokenExpires()) {
            await getToken();
        }
        try {
            setTypeLoading(true)
            setLoading(true)
            const data = await axiosInstance.post(`https://dialogflow.googleapis.com/v2/${tokenData.url}:detectIntent?access_token=${tokenData.token}`, payload);
            const botMessage: Message = {
                role: 'assistant',
                text: data.data.queryResult?.fulfillmentText
            }
            setMessages(prevMessages => [...prevMessages, botMessage]);
            setLoading(false)
            setTypeLoading(false)
        } catch (error) {
            toast.error('Failed to communicate right now.');
            setTypeLoading(false)
            setLoading(false)
        }
    }, [getToken, messageText]);

    useEffect(() => {
        if (lastMessageRef?.current) {
            lastMessageRef.current.scrollIntoView({ behavior: 'smooth' });
        }
    }, [messages, typeLoading]);

    return (
        <div className='App flex items-center justify-center w-full my-0 mx-auto'>
            <Toaster
                position="top-center"
                toastOptions={{
                    duration: 2000,
                    style: {
                        background: 'rgb(203 40 40 /100%)',
                        color: '#fff',
                        fontSize: '12px',
                        fontFamily: 'font-barlow',
                        fontWeight: '600',
                        position: 'relative',
                        top: '74px',
                    },
                    icon: null,
                }}
            />
            <div className='w-full relative flex flex-col'
            >
                <div className='bg-[#0B6B20] flex items-center h-[72px] py-[13.5px] px-[15px]'>
                    <ChatIcon className="w-[40px] h-[40px] mr-[16px]" />
                    <div className='flex items-center justify-between w-[calc(100%-56px)]'>
                        <p className='font-barlow flex flex-col text-white'>
                            <span className='text-[18px] font-semibold leading-7'>Gerd</span>
                            <span className='text-[13px] leading-[18px]'>Online</span>
                        </p>
                        <span onClick={closeChatbot} className='cursor-pointer'>
                            <CloseIcon className="w-[20px] h-[20px]" />
                        </span>
                    </div>
                </div>
                <div className={`flex flex-col bg-white`}>
                    <div id="message-list" className="infinite-scroll-component relative overflow-auto ">
                        <InfiniteScroll
                            dataLength={messages.length}
                            next={() => { }}
                            hasMore={false}
                            loader={circularLoader()}
                            style={{ display: 'flex', flexDirection: 'column', height: 'calc(100vh - 136px)', padding: '30px' }}
                            scrollableTarget="message-list"
                            scrollThreshold={1}
                        >
                            {messages.length > 0 && messages.map((message, index) => (
                                <div key={index} ref={index === messages.length - 1 ? lastMessageRef : null} className={`flex ${message.role === 'user' ? 'flex-row-reverse' : ''} font-barlow mb-[16px] relative`}>
                                    {message.role === 'assistant' && (
                                        <div className="w-[24px] h-[24px] flex items-center justify-center absolute top-[-11.5px] left-[-12.5px] bg-[#0B6B20] rounded-full">
                                            <ChatIcon className='w-[12px] h-[12px]' />
                                        </div>
                                    )}
                                    <div className={`${message.role === 'user' ? 'bg-[#FAF8BE] self-end]' : 'bg-[#CBF5D5] self-start'} px-[16px] pt-[8px] pb-[10px] rounded-[4px] max-w-[calc(100%-20px)]`}>
                                        <p className='break-words font-barlow text-[15px] leading-[21px] font-regular'>{renderMessageWithLinks(message.text)}</p>
                                    </div>
                                </div>
                            ))}
                            {typeLoading && (
                                <div className="flex relative rounded-[4px]">
                                    <div className="w-[24px] h-[24px] flex items-center justify-center absolute top-[-11.5px] left-[-12.5px] bg-[#0B6B20] rounded-full">
                                        <ChatIcon className='w-[12px] h-[12px]' />
                                    </div>
                                    <div className="bg-[#CBF5D5] flex items-center justify-center rounded-[5px] px-[16px] pt-[14px] pb-[16px] w-[52px] h-[34px]">
                                        <div className="typing-loader" />
                                    </div>
                                </div>
                            )}
                        </InfiniteScroll>
                    </div>
                </div>
                <form onSubmit={handleSubmit} className="text-[15px] h-[64px] items-center border-t-[1px] border-[#01300B] flex py-[10px] pr-[15px] pl-[20px] bg-[#F0FFF4] font-barlow ">
                    <input
                        type="text"
                        autoFocus={true}
                        placeholder="Nachricht schreiben..."
                        className={`placeholder-[#01300B] w-full flex-1 pr-[10px] p-3 bg-[#F0FFF4] border border-gray-300  border-none outline-none text-[#01300B]`}
                        value={messageText}
                        maxLength={256}
                        onChange={(e) => {
                            setMessageText(e.target.value);
                        }}
                    />
                    <button type='submit' className='flex items-center justify-end w-[25px] min-w-[25px] h-[25px] w-[20px] h-[20px]' disabled={loading}>
                        <SendIcon />
                    </button>
                </form>
            </div >
        </div>
    );
};

const renderMessageWithLinks = (text: string) => {
    const urlRegex = /(https?:\/\/[^\s]+)/g;
    return text.split(urlRegex).map((part, index) => {
        // If the part matches the URL pattern, render it as a clickable link
        if (part.match(urlRegex)) {
            return (
                <a key={index} href={part} className="text-blue-600 underline" target="_blank" rel="noopener noreferrer">
                    {part}
                </a>
            );
        }
        // Otherwise, render the text as it is
        return part;
    });
};

const circularLoader = () => (
    <div className="flex justify-center">
        <div className="circular-loader"></div>
    </div>
);

export default UmWeltBot;
