import React from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import UmWeltBot from 'feature/umwelt-boat/UmWeltBot';
import UmWeltDemo from 'feature/umwelt-boat/umWeltDemo';

const App: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/iframe" element={<UmWeltBot />} />
        <Route path="/bot" element={<UmWeltDemo />} />
        {/* Catch-all route to display a NotFound component */}
        <Route path="*" element={<Navigate to="/bot" />} />
      </Routes>
    </Router >
  );
};

export default App;
