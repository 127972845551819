interface IProps {
    className?: string;
}

export const ChatIcon: React.FC<IProps> = ({ className }) => (
    <svg viewBox="0 0 40 41" className={className}>
        <path d="M33.3333 10.5H31.6666V23.8333C31.6666 24.75 30.9166 25.5 29.9999 25.5H9.99992V27.1666C9.99992 29 11.4999 30.5 13.3333 30.5H29.9999L36.6666 37.1666V13.8333C36.6666 12 35.1666 10.5 33.3333 10.5ZM28.3333 18.8333V7.16665C28.3333 5.33331 26.8333 3.83331 24.9999 3.83331H6.66659C4.83325 3.83331 3.33325 5.33331 3.33325 7.16665V28.8333L9.99992 22.1666H24.9999C26.8333 22.1666 28.3333 20.6666 28.3333 18.8333Z" fill="#6BF389" />
    </svg>

);

export const CloseIcon: React.FC<IProps> = ({ className }) => (
    <svg viewBox="0 0 20 20" className={className}>
        <path d="M15.2501 4.75843C14.9251 4.43343 14.4001 4.43343 14.0751 4.75843L10.0001 8.8251L5.9251 4.7501C5.6001 4.4251 5.0751 4.4251 4.7501 4.7501C4.4251 5.0751 4.4251 5.6001 4.7501 5.9251L8.8251 10.0001L4.7501 14.0751C4.4251 14.4001 4.4251 14.9251 4.7501 15.2501C5.0751 15.5751 5.6001 15.5751 5.9251 15.2501L10.0001 11.1751L14.0751 15.2501C14.4001 15.5751 14.9251 15.5751 15.2501 15.2501C15.5751 14.9251 15.5751 14.4001 15.2501 14.0751L11.1751 10.0001L15.2501 5.9251C15.5668 5.60843 15.5668 5.0751 15.2501 4.75843Z" fill="white" />
    </svg>
);

export const SendIcon: React.FC<IProps> = ({ className }) => (
    <svg width="20" height="20" viewBox="0 0 20 20" className={className}>
        <path d="M3.05924 17L17.6009 10.7667C18.2759 10.475 18.2759 9.52499 17.6009 9.23333L3.05924 2.99999C2.50924 2.75833 1.90091 3.16666 1.90091 3.75833L1.89258 7.59999C1.89258 8.01666 2.20091 8.37499 2.61758 8.42499L14.3926 9.99999L2.61758 11.5667C2.20091 11.625 1.89258 11.9833 1.89258 12.4L1.90091 16.2417C1.90091 16.8333 2.50924 17.2417 3.05924 17Z" fill="#01300B" />
    </svg>

);


